
.word-cloud-component1 {
    //width: 590px;
    //height: 350px;



    width: 890px;
    height: 529px;
    margin-left: -150px;
    margin-top: -89px;

    //width: 880px;
    //height: 530px;
    //margin-left: -145px;
    //margin-top: -90px;

    
}
.word-cloud-component2 {
    width: 300px;
    height: 220px;
}
